import {
  CreateRepository,
  UpdateProjectName,
  UpdateProjectSettingsDetectTemplateIssues,
  UpdateProjectSettingsGateId,
  UpdateProjectSettingsIgnoreFixable,
  UpdateProjectSettingsPageTemplates,
  UpdateProjectSettingsUrlParams,
  Integration,
  LawsuitRiskStatus,
  ProjectType,
  ScanRuleLevel,
  ScanRuleSeverity,
  ScanScoreOutcome,
  TestFramework,
  AccessibilityScoreFilter,
  AnalysisScope,
  Alm,
  NumberScansFilter,
  AlmInstanceDocumentDetailed,
  SetDefaultAssignee,
} from '@userway/cicd-api'
import { z } from 'zod'
import * as fromSearch from '~/feat/projects/search'
import { http, messageResponseSchema } from '~/lib/http'
import { isHttpError, q } from '~/lib/query'
import { encodeSearchParams } from '~/lib/utils/form-data'
import { zodEnumRecord } from '~/lib/utils/zod-enum-record'

const createManualProjectResponseSchema = z.object({
  payload: z.object({
    id: z.string(),
    projectName: z.string(),
    type: z.string(),
    createdAt: q.dateSchema,
    updatedAt: q.dateSchema,
  }),
})
export async function createManualProject(
  organization: string,
  payload: CreateRepository,
  signal?: AbortSignal | null,
) {
  const res = await http.post(
    `organizations/${organization}/manual/repositories`,
    { json: payload, signal: signal || null },
  )
  const json = await res.json()
  return createManualProjectResponseSchema.parse(json)
}

const getProjectInfoResponseSchema = q.responseSchema(
  z.object({
    id: z.string(),
    name: z.string(),
    organizationId: z.string(),
    type: z.string(),
    integration: z.nativeEnum(Integration),
    scanned: z.boolean(),
    createdAt: q.dateSchema,
    updatedAt: q.dateSchema,
  }),
)
export async function getProjectInfo(
  organizationSlug: string,
  projectName: string,
) {
  const json = await http
    .get(`organizations/${organizationSlug}/projects/${projectName}/brief-info`)
    .json()
  return getProjectInfoResponseSchema.parse(json)
}

const getProjectSetupResponseSchema = q.responseSchema(
  z.object({
    repositoryVariablesURL: z.string().nullable(),
    testFrameworks: z.array(z.nativeEnum(TestFramework)),
    almType: z.nativeEnum(Alm),
    projectToken: z.string(),
  }),
)
export async function getProjectSetup(
  organizationSlug: string,
  projectName: string,
) {
  const json = await http
    .get(
      `organizations/${organizationSlug}/projects/${projectName}/setup-instructions`,
    )
    .json()
  return getProjectSetupResponseSchema.parse(json)
}

const bitbucketPipelineVersionResponseSchema = q.responseSchema(
  z.object({ version: z.string() }),
)

export async function getBitbucketPipelineVersion() {
  const json = await http
    .get(`setup-instructions/bitbucket-pipe/version`)
    .json()
  return bitbucketPipelineVersionResponseSchema.parse(json)
}

// const projectNameExistsResponseSchema = z.boolean()
export async function projectNameExists(
  organizationSlug: string,
  projectName: string,
) {
  try {
    await http.get(
      `organizations/${organizationSlug}/projects/${projectName}/brief-info`,
    )
    return true
  } catch (httpError) {
    if (isHttpError(httpError)) {
      if (httpError.response.status === 404) return false
    }
    throw httpError
  }
}

/** Add some type-level test to verify that z.infer matches with continuous-a11y-api type */
const searchProjectsResponseSchema = q.paginatedResponseSchema(
  z.object({
    id: z.string(),
    integration: z.nativeEnum(Integration),
    name: z.string(),
    organizationSlug: z.string(),
    qualityGateStatus: z.nativeEnum(ScanScoreOutcome).nullable(),
    recentScanId: z.string().nullable(),
    testFrameworks: z.array(z.nativeEnum(TestFramework)),
    type: z.nativeEnum(ProjectType),
    createdAt: q.dateSchema,
    analyzedAt: q.dateSchema.nullable(),
    scoreCells: z.array(q.scoreCellSchema),
    scope: z.nativeEnum(AnalysisScope).nullable(),
    scanned: z.boolean(),
    connected: z.boolean(),
    scans: z.object({
      managed: z.number(),
      selfHosted: z.number(),
    }),
    externalWorkspaceName: z.string().nullable(),
    browsers: z.array(z.string()),
  }),
)

export type SearchProjectsResponse = z.infer<
  typeof searchProjectsResponseSchema
>

export async function searchProjects(
  organization: string,
  filters: fromSearch.Filters,
  signal?: AbortSignal | undefined,
) {
  const res = await http.get(`organizations/${organization}/projects/search`, {
    searchParams: encodeSearchParams(filters, { server: true }),
    signal: signal || null,
  })
  const json = await res.json()
  return searchProjectsResponseSchema.parse(json)
}

const facetsProjectsResponseSchema = q.responseSchema(
  z
    .object({
      [fromSearch.name.wcag]: zodEnumRecord(
        z.nativeEnum(ScanRuleLevel),
        z.number(),
      ),
      [fromSearch.name.severity]: zodEnumRecord(
        z.nativeEnum(ScanRuleSeverity),
        z.number(),
      ),
      [fromSearch.name.qualityGateStatus]: zodEnumRecord(
        z.nativeEnum(ScanScoreOutcome),
        z.number(),
      ),
      [fromSearch.name.lawsuitRisk]: zodEnumRecord(
        z.nativeEnum(LawsuitRiskStatus),
        z.number(),
      ),
      [fromSearch.name.a11yScore]: zodEnumRecord(
        z.nativeEnum(AccessibilityScoreFilter),
        z.number(),
      ),
      [fromSearch.name.testFrameworks]: zodEnumRecord(
        z.nativeEnum(TestFramework),
        z.number(),
      ),
      integration: zodEnumRecord(z.nativeEnum(Alm), z.number()),
      managedScans: zodEnumRecord(z.nativeEnum(NumberScansFilter), z.number()),
      selfHostedScans: zodEnumRecord(
        z.nativeEnum(NumberScansFilter),
        z.number(),
      ),
    })
    .nullish()
    .catch((x) => {
      console.error('facetsProjectsResponseSchema failed to parse', x)
      return null
    }),
)

export type ProjectsFacets = z.infer<
  typeof facetsProjectsResponseSchema
>['payload']

export async function searchProjectsFacets(
  organization: string,
  filters: fromSearch.Filters,
  signal?: AbortSignal | undefined,
) {
  const res = await http.get(`organizations/${organization}/projects/facets`, {
    searchParams: encodeSearchParams(filters, { server: true }),
    signal: signal || null,
  })
  const json = await res.json()
  return facetsProjectsResponseSchema.parse(json)
}

const projectSettingsResponseSchema = z.object({
  payload: z.object({
    qualityGateId: z.string(),
    id: z.string(),
    ignoreFixable: z.boolean(),
    detectTemplateLevelIssues: z.boolean(),
    qualityGateEnabled: z.boolean(),
    projectName: z.string(),
    decryptedToken: z.string(),
    qualityGateName: z.string(),
    urlParamsEnabled: z.boolean(),
    pageTemplates: z.array(z.string()),
    alm: z.object({
      mainBranch: z.string(),
      mainBranchId: z.string(),
    }),
    defaultAssigneeId: z.string().nullable(),
  }),
})

export async function getProjectSettings(
  organizationSlug: string,
  projectSlug: string,
  signal?: AbortSignal | undefined,
) {
  const res = await http.get(
    `organizations/${organizationSlug}/projects/${projectSlug}/settings`,
    {
      signal: signal || null,
    },
  )
  const json = await res.json()
  return projectSettingsResponseSchema.parse(json)
}

export async function deleteProject(
  organizationSlug: string,
  projectSlug: string,
  signal?: AbortSignal | undefined,
) {
  const res = await http.delete(
    `organizations/${organizationSlug}/projects/${projectSlug}`,
    {
      signal: signal || null,
    },
  )
  const json = await res.json()
  return q.responseSchema(q.messageResponseSchema).parse(json)
}

export async function changeOrganizationName(
  {
    organizationSlug,
    projectSlug,
  }: { organizationSlug: string; projectSlug: string },
  payload: UpdateProjectName,
  signal?: AbortSignal | undefined,
) {
  const res = http.post(
    `organizations/${organizationSlug}/projects/${projectSlug}/settings/name`,
    { json: payload, signal: signal || null },
  )
  const json = await res.json()
  return messageResponseSchema.parse(json)
}

export async function changeOrganizationIgnoreFixable(
  {
    organizationSlug,
    projectSlug,
  }: { organizationSlug: string; projectSlug: string },
  payload: UpdateProjectSettingsIgnoreFixable,
  signal?: AbortSignal | undefined,
) {
  const res = http.post(
    `organizations/${organizationSlug}/projects/${projectSlug}/settings/ignore-fixable`,
    { json: payload, signal: signal || null },
  )
  const json = await res.json()
  return messageResponseSchema.parse(json)
}

export async function changeOrganizationDetectTemplate(
  {
    organizationSlug,
    projectSlug,
  }: { organizationSlug: string; projectSlug: string },
  payload: UpdateProjectSettingsDetectTemplateIssues,
  signal?: AbortSignal | undefined,
) {
  const res = http.post(
    `organizations/${organizationSlug}/projects/${projectSlug}/settings/detect-template`,
    { json: payload, signal: signal || null },
  )
  const json = await res.json()
  return messageResponseSchema.parse(json)
}

export async function changeOrganizationQualityGate(
  {
    organizationSlug,
    projectSlug,
  }: { organizationSlug: string; projectSlug: string },
  payload: UpdateProjectSettingsGateId,
  signal?: AbortSignal | undefined,
) {
  const res = http.post(
    `organizations/${organizationSlug}/projects/${projectSlug}/settings/quality-gate`,
    { json: payload, signal: signal || null },
  )
  const json = await res.json()
  return messageResponseSchema.parse(json)
}

export async function changeOrganizationPageTemplates(
  {
    organizationSlug,
    projectSlug,
  }: { organizationSlug: string; projectSlug: string },
  payload: UpdateProjectSettingsPageTemplates,
  signal?: AbortSignal | undefined,
) {
  const res = http.post(
    `organizations/${organizationSlug}/projects/${projectSlug}/settings/page-templates`,
    { json: payload, signal: signal || null },
  )
  const json = await res.json()
  return messageResponseSchema.parse(json)
}

export async function changeOrganizationUrlParams(
  {
    organizationSlug,
    projectSlug,
  }: { organizationSlug: string; projectSlug: string },
  payload: UpdateProjectSettingsUrlParams,
  signal?: AbortSignal | undefined,
) {
  const res = http.post(
    `organizations/${organizationSlug}/projects/${projectSlug}/settings/url-params`,
    { json: payload, signal: signal || null },
  )
  const json = await res.json()
  return messageResponseSchema.parse(json)
}

export async function getProjectDetails(
  projectName: string,
  organizationSlug: string,
  signal?: AbortSignal | undefined,
) {
  const json = await http
    .get(`organizations/${organizationSlug}/projects/${projectName}/summary`, {
      signal: signal || null,
    })
    .json()
  return json as q.ResponseBody<AlmInstanceDocumentDetailed>
}

export async function changeDefaultProjectAssignee(
  {
    organizationSlug,
    projectSlug,
  }: { organizationSlug: string; projectSlug: string },
  payload: SetDefaultAssignee,
  signal?: AbortSignal | undefined,
) {
  const res = http.post(
    `organizations/${organizationSlug}/projects/${projectSlug}/settings/assignee/default`,
    { json: payload, signal: signal || null },
  )
  const json = await res.json()
  return json
}
